<template>
    <div class="p-2">
        <DataTable
            v-model:filters="filters" v-model:selection="selectedVariants"
            :value="variantsIn" paginator
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            :rows="20" dataKey="id" :rowHover="true" filterDisplay="menu" removableSort scrollable
            :scrollHeight="height + 'px'"
            :globalFilterFields="['product.name', 'product.category.name', 'product.brand.name']">
            <template #header>
                <div class="flex items-center justify-end gap-2">
                    <Button v-if="filters['global'].value" class="!text-sm" type="button" icon="pi pi-filter-slash"
                            label="Ryd"
                            outlined @click="clearFilter()" />
                    <IconField>
                        <InputIcon class="!top-[45%]">
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" class="!text-sm w-[400px]"
                                   placeholder="Søg produkt navn, kategori, mærke" />
                    </IconField>
                </div>
            </template>
            <template #empty>Ingen produkter fundet.</template>
            <Column v-if="adminPage" selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <!--            <template #paginatorstart>-->
            <!--                <Button type="button" icon="pi pi-refresh" text />-->
            <!--            </template>-->
            <!--            <template #paginatorend>-->
            <!--                <Button type="button" icon="pi pi-download" text />-->
            <!--            </template>-->
            <template #loading>
                <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                    <Skeleton width="60%" height="1rem" />
                </div>
            </template>
            <!--            <Column expander style="width: 5rem" />-->
            <Column field="product.name" header="Produkt" sortable sortField="product.name" filterField="product.name"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.product.name) }}
                                </div>
                            </template>
                            <span class="font-light">Produkt navn er: <span
                                class="font-bold">{{ $translate(item.data.product.name)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="product.category.name" header="Kategori"
                    :sortable="!onlySoldProducts"
                    sortField="product.category.name"
                    filterField="product.category.name"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.product.category.name) }}
                                </div>
                            </template>
                            <span class="font-light">Kategorien er: <span
                                class="font-bold">{{ $translate(item.data.product.category.name)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="product.brand.name" header="Mærke" :sortable="!onlySoldProducts"
                    sortField="product.brand.name"
                    filterField="product.brand.name"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.product.brand" class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.product.brand.name) }}
                                </div>
                            </template>
                            <span class="font-light">Brandet er: <span
                                class="font-bold">{{ $translate(item.data.product.brand.name)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="size" header="Str." :sortable="!onlySoldProducts" sortField="size"
                    filterField="size"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.size" class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.size.name) }}
                                </div>
                            </template>
                            <span class="font-light">Størrelsen er: <span
                                class="font-bold">{{ $translate(item.data.size.name)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column field="colors" header="Farve(r)" filterField="color"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.colors && item.data.colors.length" class="flex items-center mb-1">
                        <div v-for="color in item.data.colors">
                            <tooltip>
                                <template v-slot:triggerText>
                                    <color-badge model="color" :show-name="false" :name="color.name"
                                                 :hex="color.hex_code"
                                                 class="mr-1" />
                                </template>
                                <span class="font-light">Farve: <span
                                    class="font-bold">{{ $translate(color.name)
                                    }}</span></span>
                            </tooltip>
                        </div>
                    </div>
                    <div v-if="item.data.patterns && item.data.patterns.length" class="flex items-center mb-1">
                        <span v-for="pattern in item.data.patterns">
                            <tooltip>
                                <template v-slot:triggerText>
                                    {{ pattern.name }}
                                </template>
                                <span class="font-light">Mønster: <span
                                    class="font-bold">{{ $translate(pattern.name)
                                    }}</span></span>
                            </tooltip>
                        </span>
                    </div>
                </template>
            </Column>
            <Column field="product.title" header="Titel" :sortable="!onlySoldProducts" sortField="product.title"
                    filterField="product.title"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.product.title" class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.product.title) }}
                                </div>
                            </template>
                            <span class="font-light">Titlen er: <span
                                class="font-bold">{{ $translate(item.data.product.title)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="product.description" header="Beskrivelse"
                    :sortable="!onlySoldProducts"
                    sortField="product.description"
                    filterField="product.description"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.product.description" class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.product.description) }}
                                </div>
                            </template>
                            <span class="font-light">Produkt beskrivelse: <span
                                class="font-bold">{{ $translate(item.data.product.description)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column field="price" header="Pris" sortable sortField="price"
                    filterField="price"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    <price :price="item.data.price"></price>
                                </div>
                            </template>
                            <span class="font-light">Prisen: <span
                                class="font-bold">
                                <price :price="item.data.price"></price>
                            </span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="created_at" header="Oprettet" sortable sortField="created_at"
                    filterField="created_at"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.created_at) }}
                                </div>
                            </template>
                            <span class="font-light">Produkt navn er: <span
                                class="font-bold">{{ $translate(item.data.created_at)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="onlySoldProducts" field="sold_at" header="Solgt" sortable sortField="sold_at"
                    filterField="sold_at"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="topolis-tag lime-badge">
                                    {{ formatDate(item.data.sold_at) }}
                                </div>
                            </template>
                            <span class="font-light">Produktet blev solgt d. <span
                                class="font-bold">{{ formatDate(item.data.sold_at)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="adminPage" field="product.end_date" header="Slut" sortable sortField="product.end_date"
                    filterField="product.end_date" class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center gap-1">
                        <div>
                            <div v-if="item.data.product.booking.concept !== 'business'">
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <week-color :end-date="item.data.product.booking.end_date" />
                                    </template>
                                    <div>Booking slutter i uge
                                        {{ getIsoWeek(item.data.product.booking.end_date) }}
                                        (d. {{ formatDateLL(item.data.product.booking.end_date) }})
                                    </div>
                                </tooltip>
                            </div>
                            <div v-else>
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <div
                                            class="topolis-badge topolis-border-badge topolis-small-badge topolis-pearl-badge"
                                            title="Virksomhedsbooking">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4"
                                                 viewBox="0 0 24 24">
                                                <g transform="translate(-108 -188)">
                                                    <path
                                                        d="M5.751,16h8c4.02,0,4.74-1.61,4.95-3.57l.75-8c.27-2.44-.43-4.43-4.7-4.43h-10C.481,0-.219,1.99.051,4.43l.75,8C1.011,14.39,1.731,16,5.751,16Z"
                                                        transform="translate(110.249 194)" fill="none"
                                                        stroke="currentColor"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.5" />
                                                    <path d="M0,4V3.2C0,1.43,0,0,3.2,0H4.8C8,0,8,1.43,8,3.2V4"
                                                          transform="translate(116 190)" fill="none"
                                                          stroke="currentColor"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="1.5" />
                                                    <path
                                                        d="M4,1V2.02C4,3.11,3.99,4,2,4S0,3.12,0,2.03V1C0,0,0,0,1,0H3C4,0,4,0,4,1Z"
                                                        transform="translate(118 200)" fill="none" stroke="currentColor"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.5" />
                                                    <path d="M7.65,0A16.484,16.484,0,0,1,0,3.02"
                                                          transform="translate(122 199)"
                                                          fill="none" stroke="currentColor" stroke-linecap="round"
                                                          stroke-linejoin="round" stroke-width="1.5" />
                                                    <path d="M0,0A16.283,16.283,0,0,0,7.38,2.76"
                                                          transform="translate(110.62 199.27)"
                                                          fill="none" stroke="currentColor" stroke-linecap="round"
                                                          stroke-linejoin="round" stroke-width="1.5" />
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <div>Virksomhedsbooking er aktiv
                                    </div>
                                </tooltip>
                            </div>
                        </div>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="status" header="Status" sortable sortField="status"
                    filterField="status" class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="topolis-tag" :class="tagClass(item.data.status)">
                                    {{ $translate(item.data.status) }}
                                </div>
                            </template>
                            <span class="font-light">Booking status er: <span
                                class="font-bold">{{ $translate(item.data.status)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
                <!--                <template #filter="{ filterModel }">-->
                <!--                    <PrimeMultiSelect v-model="selectedStatuses"-->
                <!--                                      :options="statuses" optionLabel="name" multiple-->
                <!--                                      placeholder="Select One" title="status" display="chip">-->
                <!--                        <template #chip="slotProps">-->
                <!--                            <div class="topolis-tag" :class="tagClass(slotProps.value)">-->
                <!--                                {{ $translate(slotProps.value) }}-->
                <!--                            </div>-->
                <!--                        </template>-->
                <!--                    </PrimeMultiSelect>-->
                <!--                </template>-->
            </Column>
            <Column class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center justify-end gap-3">
                        <a :href="`/mit-minitopolis/${item.data.product.booking.uuid}/dine-produkter/${item.data.product.uuid}/${item.data.uuid}`"
                           class="text-indigo-600 hover:text-indigo-900"
                           title="Gå til booking">
                            <span class="sr-only">Go to booking</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" opacity="0.6"
                                 class="flex-shrink-0 min-w-0 w-5 h-5 text-office-black hover:text-office-black/[0.7]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </div>
                </template>
            </Column>
            <!--            <template #expansion="slotProps">-->
            <!--                <div class="p-4">-->
            <!--                    <h5>Orders for {{ slotProps.data.name }}</h5>-->
            <!--                    <DataTable :value="slotProps.data.orders">-->
            <!--                        <Column field="id" header="Id" sortable></Column>-->
            <!--                        <Column field="customer" header="Customer" sortable></Column>-->
            <!--                        <Column field="date" header="Date" sortable></Column>-->
            <!--                        <Column field="amount" header="Amount" sortable>-->
            <!--                            <template #body="slotProps">-->
            <!--                                {{ formatCurrency(slotProps.data.amount) }}-->
            <!--                            </template>-->
            <!--                        </Column>-->
            <!--                        <Column field="status" header="Status" sortable>-->
            <!--                            <template #body="slotProps">-->
            <!--                                <Tag :value="slotProps.data.status.toLowerCase()"-->
            <!--                                     :severity="getOrderSeverity(slotProps.data)" />-->
            <!--                            </template>-->
            <!--                        </Column>-->
            <!--                        <Column headerStyle="width:4rem">-->
            <!--                            <template #body>-->
            <!--                                <Button icon="pi pi-search" />-->
            <!--                            </template>-->
            <!--                        </Column>-->
            <!--                    </DataTable>-->
            <!--                </div>-->
            <!--            </template>-->
        </DataTable>
    </div>
</template>

<script>
    import ColorBadge from "./ColorBadge.vue";
    import RateUser from "../RateUser.vue";
    import WeekColor from "./WeekColor.vue";
    import UserConcept from "../UserConcept.vue";
    import UserTask from "../UserTask.vue";
    import UserStoreId from "../UserStoreId.vue";
    import UserAvatar from "../UserAvatar.vue";
    import Tooltip from "../Tooltip.vue";
    import AssignUser from "../AssignUser.vue";
    import LoadingSpinner from "./LoadingSpinner.vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import ColumnGroup from "primevue/columngroup";
    import Row from "primevue/row";
    import IconField from "primevue/iconfield";
    import InputIcon from "primevue/inputicon";
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import { FilterMatchMode, FilterOperator } from "@primevue/core/api";
    import DatePicker from "primevue/datepicker";
    import MultiSelect from "@vueform/multiselect";
    import Select from "primevue/select";
    import Tag from "primevue/tag";
    import Skeleton from "primevue/skeleton";
    import axios from "axios";
    import Price from "../../Shop/Price.vue";

    export default {
        name: "ProductTable",
        components: {
            AssignUser,
            UserTask,
            WeekColor,
            LoadingSpinner,
            UserAvatar,
            RateUser,
            Tooltip,
            UserConcept,
            UserStoreId,
            DataTable,
            Column,
            ColumnGroup,
            Row,
            IconField,
            InputIcon,
            InputText,
            Button,
            FilterMatchMode,
            FilterOperator,
            DatePicker,
            PrimeMultiSelect: MultiSelect,
            Select,
            Tag,
            Skeleton,
            Price,
            ColorBadge
        },
        props: {
            booking: {},
            locale: {},
            height: {},
            adminPage: {
                type: Boolean,
                required: false,
                default: false
            },
            onlySoldProducts: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                visibleGroups: {},
                admins: [],
                variantsIn: [],
                isLoading: false,
                selectedVariants: null,
                selectedStatuses: null,
                selectedAdmins: null,
                filters: null,
                expandedRows: {},
                statuses: ["approved", "completed", "pending", "active", "paid", "declined", "refunded", "stalled", "discontinued", "cancelled", "finished"]
            };
        },
        created() {
            this.fetchProducts();
            this.initFilters();
        },
        mounted() {
            window.addEventListener("keyup", this.handleKeyUp);
        },
        beforeUnmount() {
            window.removeEventListener("keyup", this.handleKeyUp);
        },
        methods: {
            handleKeyUp(event) {
                if (event.key === "Escape" && this.filters["global"].value) {
                    this.clearFilter();
                }
            },
            generateURL(product, variant) {
                return `/mit-minitopolis/${this.booking.uuid}/dine-produkter/${product.uuid}/${variant.uuid}`;
            },
            formatDate(dateString) {
                const date = new Date(dateString);
                return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
            },
            async fetchProducts(payload) {
                console.log("gsfg", this.booking);
                this.isLoading = true;
                try {
                    // Make a GET request to your new endpoint
                    const response = await axios.get(`/${this.booking.uuid}/get-sold-products-from-booking`);

                    this.variantsIn = response.data.variants;

                    console.log("productttt", this.variantsIn);

                    if (payload) {
                        this.emitter.emit("show-toast", {
                            message: payload.message,
                            type: payload.success ? "success" : "error"
                        });
                    }

                    this.isLoading = false;

                } catch (error) {
                    console.error(error);
                    this.isLoading = false;
                }
            },
            clearFilter() {
                this.initFilters();
            },
            initFilters() {
                this.filters = {
                    ...this.filters,
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    "product.name": {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
                    },
                    "product.category.name": {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
                    },
                    "product.brand.name": {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
                    }
                    // and other fields....
                };
            },
            tagClass(status) {
                switch (status) {
                    case "pending":
                        return "light-blue-tag";
                    case "approved":
                        return "light-orange-tag";
                    case "stalled":
                        return "pink-tag";
                    case "sold":
                        return "green-tag";
                    case "completed":
                        return "light-rose-tag";
                    case "paid":
                        return "dark-sky-tag";
                    case "cancelled":
                    case "refunded":
                    case "declined":
                    case "finished":
                    case "discontinued":
                        return "light-skin-tag";
                    default:
                        return "gray-tag";
                }
            },
            getIsoWeek(date) {
                const targetDate = new Date(date);
                const dayNumber = (targetDate.getUTCDay() + 6) % 7;
                targetDate.setDate(targetDate.getDate() - dayNumber + 3);
                const firstThursday = targetDate.valueOf();
                targetDate.setMonth(0, 1);
                if (targetDate.getDay() !== 4) {
                    targetDate.setMonth(0, 1 + ((4 - targetDate.getDay()) + 7) % 7);
                }
                return 1 + Math.ceil((firstThursday - targetDate) / 604800000);
            },
            formatDateLL(date) {
                const options = { year: "numeric", month: "long", day: "numeric" };
                return new Date(date).toLocaleDateString(this.locale, options);
            },
            getGradientColor(count) {
                if (count <= 1) {
                    return "fill-green-200"; // light green
                } else if (count <= 3) {
                    return "fill-teal-400"; // medium-light green
                } else if (count > 3) {
                    return "fill-blue-400"; // dark green
                } else {
                    return "fill-sky-200";
                }
            },
            getGradientExtendColor(count) {
                if (count <= 1) {
                    return "fill-rose-300"; // light green
                } else if (count <= 2) {
                    return "fill-red-600"; // medium-light green
                } else if (count > 3) {
                    return "fill-red-800"; // dark green
                }
            }
        }
    };
</script>

<style>
    .p-icon.p-datatable-sort-icon {
        width: 0.75rem;
        height: 0.75rem;
        margin-bottom: 0.25rem;
    }

    .p-icon.p-button-icon {
        width: 0.75rem;
        height: 0.75rem;
    }
</style>