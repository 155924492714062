<template>
    <div v-if="tenantName">
        <div class="flex flex-col lg:flex-row justify-between w-full bg-[#F5F5F5] border border-[#E6E6E6]"
             :class="{'rounded-2xl lg:rounded-bl-none lg:rounded-br-none lg:rounded-tl-2xl' : showAdvancedContent, 'rounded-2xl' : !showAdvancedContent  }">
            <div
                :class="{'rounded-t-2xl lg:rounded-r-none lg:rounded-tl-2xl lg:rounded-bl-none' : showAdvancedContent, 'rounded-t-2xl lg:rounded-r-none lg:rounded-l-2xl' : !showAdvancedContent  }"
                class="flex w-full xl:w-1/2 bg-[#FCFCFC] lg:border-r lg:border-[#E6E6E6] p-4">
                <div>
                    <div class="w-full space-y-12">
                        <!--Product creation fields-->
                        <div class="flex items-center flex-wrap gap-4 w-full">
                            <div class="flex xs:hidden w-full items-center justify-between flex-wrap gap-2 xs:gap-4">
                                <button
                                    :class="{ 'admin-gray-button h-fit': adminPage, 'button button-gray smallest-button w-full xxs:w-auto': !adminPage }"
                                    @click="goBack">
                                    <span
                                        class="hidden sm:inline-block lg:hidden 2xl:inline-block">Afslut
                                        oprettelsesmode</span>
                                    <span class="inline-block sm:hidden lg:inline-block 2xl:hidden">Afslut</span>
                                </button>
                                <div class="flex items-center flex-wrap justify-end gap-2 w-full xxs:w-auto">
                                    <button v-if="!editMode"
                                            :class="{ 'admin-blue-button h-fit': adminPage, 'button button-blue smallest-button w-full xxs:w-auto': !adminPage }"
                                            :disabled="!isValid(product)" @click="saveProduct(product)">
                                        <span>Opret produkt</span>
                                    </button>
                                    <div v-if="editMode"
                                         class="flex items-center flex-wrap gap-2 xs:gap-4 w-full xxs:w-auto">
                                        <button
                                            :class="{ 'admin-blue-button h-fit': adminPage, 'button button-blue smallest-button w-full xxs:w-auto': !adminPage }"
                                            :disabled="!isValid(product)"
                                            @click="updateProduct(product, goToNewProduct)">
                                            <span>Opdatér & afslut</span>
                                        </button>
                                        <div class="relative flex flex-col w-full xxs:w-auto"
                                             :class="{ 'mt-2 xs:mt-0': !adminPage && !notReachedProductLimit() }">
                                            <button
                                                :class="{ 'admin-border-button h-fit': adminPage, 'button border-button smallest-button w-full xxs:w-auto': !adminPage }"
                                                :disabled="!isValid(product) || !notReachedProductLimit() || ((booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)"
                                                @click="updateAndContinue(product)"
                                            >
                                                <span>Opdatér & fortsæt</span>
                                            </button>
                                            <span v-if="!adminPage && !notReachedProductLimit()"
                                                  class="absolute -top-4 left-0 right-0 mx-auto w-fit text-xxs font-bold text-topolis-purple-600">Du
                                                kan ikke oprette flere</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--TARGET SEGMENT-->
                            <div class="flex flex-col w-full xs:w-1/2 lg:w-full xl:w-1/2 xs:pr-2 lg:pr-0 xl:pr-2"
                                 ref="targetDropdown">
                                <div class="flex items-center">
                                    <label class="form-label" :for="'target_segment-' + product.id">Univers</label>
                                    <div class="ml-1 -mt-1.5 text-amber-800 font-bold cursor-help"
                                         v-if="shouldDisableDropdown"
                                         title="Til et allerede oprettet produkt med overkategorien, 'Legetøj' eller 'Bøger & Spil', så kan du ikke opdatere univers til MAMA">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 h-4"
                                             viewBox="0 0 24 24">
                                            <g transform="translate(-108 -508)">
                                                <path
                                                    d="M8.745.442a2,2,0,0,1,2.51,0l1.58,1.35a2.31,2.31,0,0,0,1.27.46h1.7a1.938,1.938,0,0,1,1.93,1.93v1.7a2.279,2.279,0,0,0,.46,1.26l1.35,1.58a2,2,0,0,1,0,2.51l-1.35,1.58a2.279,2.279,0,0,0-.46,1.26v1.7A1.938,1.938,0,0,1,15.8,17.7h-1.7a2.279,2.279,0,0,0-1.26.46l-1.58,1.35a2,2,0,0,1-2.51,0l-1.58-1.35a2.307,2.307,0,0,0-1.26-.46H4.165a1.938,1.938,0,0,1-1.93-1.93v-1.71a2.237,2.237,0,0,0-.45-1.25l-1.35-1.59a2.013,2.013,0,0,1,0-2.5l1.35-1.59a2.264,2.264,0,0,0,.45-1.25V4.192a1.938,1.938,0,0,1,1.93-1.93h1.73a2.279,2.279,0,0,0,1.26-.46Z"
                                                    transform="translate(110.005 510.008)" fill="none"
                                                    stroke="currentColor"
                                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path d="M0,0V4.83" transform="translate(120 516.13)"
                                                      fill="none" stroke="currentColor" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="1.5" />
                                                <path d="M0,0H.009" transform="translate(119.995 524)"
                                                      fill="none" stroke="currentColor" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2" />
                                            </g>
                                        </svg>
                                    </div>
                                </div>

                                <div class="relative w-full">
                                    <div class="flex rounded-lg divide-x divide-office-black w-full cursor-pointer"
                                         @click="!shouldDisableDropdown && (showTargetSegmentDropdown = !showTargetSegmentDropdown) || isEasyBookingsDisabled"
                                         :class="{ 'cursor-not-allowed opacity-50': shouldDisableDropdown || isEasyBookingsDisabled }">
                                        <div class="relative z-0 flex rounded-lg w-full">
                                            <div
                                                class="relative flex items-center py-2 px-3 border border-gray-300 border-r-none rounded-l-lg w-full">
                                                <svg v-if="product.target_segment"
                                                     class="h-5 w-5 text-purple-900"
                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                     fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clip-rule="evenodd" />
                                                </svg>
                                                <span
                                                    class="inline-flex ml-1 xs:ml-2.5 text-xs xs:text-sm font-medium text-purple-700 truncate select-none"
                                                    :class="{ 'text-black': product.target_segment }">{{ targetSegment
                                                    }}</span>
                                            </div>
                                            <button
                                                type="button"
                                                class="relative inline-flex items-center p-2 border-r border-y border-gray-300 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-gray-100 focus:outline-none"
                                            >
                                                <svg
                                                    :class="{'rotate-180': showTargetSegmentDropdown && !shouldDisableDropdown && !isEasyBookingsDisabled, 'rotate-0': !showTargetSegmentDropdown}"
                                                    class="h-5 w-5 text-gray-500 transition transform duration-300"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                    fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd"
                                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                          clip-rule="evenodd" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <ul v-if="showTargetSegmentDropdown && !shouldDisableDropdown && !isEasyBookingsDisabled"
                                        class="origin-top-right absolute z-[9999] right-0 mt-2 w-full rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 focus:outline-none"
                                        tabindex="-1">
                                        <li
                                            class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                            role="option"
                                            :class="{ 'bg-purple-100 hover:bg-purple-100/[0.8]': product.target_segment === 'children' }"
                                            @click="selectTargetSegment('children')"
                                        >
                                            <div class="flex-col">
                                                <div class="flex items-center justify-between">
                                                    <span class="font-medium">Børneuniverset</span>
                                                    <span class="text-purple-900">
                                                        <svg v-if="product.target_segment === 'children'"
                                                             class="h-5 w-5"
                                                             xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 20 20" fill="currentColor"
                                                             aria-hidden="true">
                                                            <path fill-rule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clip-rule="evenodd" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <p class="text-gray-500 mt-0.5">Produktet er til børn og
                                                    babyer.</p>
                                            </div>
                                        </li>
                                        <li
                                            class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                            :class="{ 'bg-purple-100 hover:bg-purple-100/[0.8]': product.target_segment === 'mama' }"
                                            role="option"
                                            @click="selectTargetSegment('mama')"
                                        >
                                            <div class="flex flex-col">
                                                <div class="flex items-center justify-between">
                                                    <span class="font-medium">MAMA universet</span>
                                                    <span class="text-purple-900">
                                                        <svg v-if="product.target_segment === 'mama'"
                                                             class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 20 20" fill="currentColor"
                                                             aria-hidden="true">
                                                            <path fill-rule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clip-rule="evenodd" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <p class="text-gray-500 mt-0.5">Produktet er til
                                                    kvinder.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="flex flex-col xs:flex-row gap-4 w-full">
                                <!--CATEGORIES-->
                                <minitopolis-autocomplete
                                    :id="'category-' + product.id"
                                    :model-value="product.category"
                                    class="w-full"
                                    label="Kategori"
                                    :items="allCategoriesExcludingParentCategories(attributes.categories)"
                                    mode="single"
                                    :product="product"
                                    send-type="name"
                                    model="category"
                                    :edit-mode="editMode"
                                    :admin-page="adminPage"
                                    placeholder="Søg efter kategori ..."
                                    :disabled="isEasyBookingsDisabled && booking.concept !== 'business' && !adminPage"
                                    @set-category-id="setCategoryId"
                                    @set-parent-category-id="setParentCategoryId($event, product)"
                                    @added-category="addCategory"
                                    @clicked-item="selectedProductValue(product, (parentCategoriesNotIncludingBooks() || (currentParentCategory && (currentParentCategory === 132 || currentParentCategory === 378))) ? 'brand-' + product.id : 'title-' + product.id)"
                                    @clear-value="clearCategoryField"
                                />
                                <!--PARENT CATEGORIES-->
                                <div v-if="adminPage" class="relative w-full">
                                    <label class="form-label">Overkategori</label>
                                    <div v-if="currentParentCategory">
                                        <span class="text-sm">{{ currentParentCategory.label }}</span>
                                    </div>
                                    <div v-if="currentParentCategory && currentParentCategory === 132">
                                        <span class="text-sm">Andet</span>
                                    </div>
                                    <div v-if="currentParentCategory && currentParentCategory === 378">
                                        <span class="text-sm">Ukendt</span>
                                    </div>
                                    <div v-if="!currentParentCategory && !product.category">
                                        <span class="text-sm">Udfyld kategori</span>
                                    </div>
                                    <div v-if="!currentParentCategory && product.category">
                                        <select v-model="new_parent_category" class="text-xs form-select"
                                                @change="setNewParentCategory(product)">
                                            <option disabled selected value="null">-- Vælg overkategori --</option>
                                            <option
                                                v-for="category in allParentCategories(attributes.categories)"
                                                :key="category.value"
                                                :value="category.value"
                                            >
                                                {{ category.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col gap-4 w-full">
                                <div class="flex flex-col xs:flex-row gap-4 w-full">
                                    <!--BRANDS-->
                                    <minitopolis-autocomplete
                                        :id="'brand-' + product.id"
                                        :model-value="product.brand"
                                        class="w-full xs:w-1/2"
                                        label="Mærke"
                                        model="brand"
                                        :edit-mode="editMode"
                                        :items="attributes.brands"
                                        mode="single"
                                        :admin-page="adminPage"
                                        send-type="name"
                                        :placeholder="(editMode && !product.category) ||
                                            (!editMode && !product.category_id) ||
                                            (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                            (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Søg efter mærke ...'"
                                        :disabled="
                                            (editMode && !product.category) ||
                                            (!editMode && !product.category_id) ||
                                            (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                            (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage)"
                                        @set-brand-id="setBrandId"
                                        @new-brand-created="newBrandCreated = $event"
                                        @clicked-item="selectedProductValue(product, (parentCategoriesEquipmentInteriorToysBooks() || (currentParentCategory && (currentParentCategory === 132 || currentParentCategory === 378))) ? 'color-' + product.id : 'size-' + product.id)"
                                        @clear-value="product.brand = null"
                                    />
                                    <!--SIZES-->
                                    <minitopolis-autocomplete
                                        :id="'size-' + product.id"
                                        :model-value="product.size"
                                        class="w-full xs:w-1/2"
                                        label="Størrelse"
                                        :items="attributes.sizes"
                                        mode="single"
                                        model="size"
                                        :edit-mode="editMode"
                                        :admin-page="adminPage"
                                        send-type="name"
                                        :placeholder="(editMode && !product.category) ||
                                            (!editMode && !product.category_id) ||
                                            parentCategoriesEquipmentInteriorToysBooks() ||
                                            (editMode && product.category_id === 132) ||
                                            (editMode && product.category_id === 378) ||
                                            (currentParentCategory && currentParentCategory === 132) ||
                                            (currentParentCategory && currentParentCategory === 378) ||
                                            (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Søg efter størrelse ...'"
                                        :disabled="
                                            (editMode && !product.category) ||
                                            (!editMode && !product.category_id) ||
                                            parentCategoriesEquipmentInteriorToysBooks() ||
                                            (editMode && product.category_id === 132) ||
                                            (editMode && product.category_id === 378) ||
                                            (currentParentCategory && currentParentCategory === 132) ||
                                            (currentParentCategory && currentParentCategory === 378) ||
                                            (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage)
                                        "
                                        @set-size-id="setSizeId"
                                        @added-size="addSize"
                                        @clicked-item="selectedProductValue(product, 'color-' + product.id)"
                                        @clear-value="product.size = null"
                                    />
                                </div>

                                <!--COLORS-->
                                <minitopolis-autocomplete
                                    :id="'color-' + product.id"
                                    v-model="product.new_colors"
                                    class="w-full"
                                    label="Farve(r)"
                                    :items="attributes.colors"
                                    mode="tags"
                                    model="colors"
                                    :edit-mode="editMode"
                                    :admin-page="adminPage"
                                    send-type="name"
                                    :placeholder="(editMode && !product.category) || (!editMode && !product.category_id) ||
                                                   (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                                   (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Søg efter farve ...'"
                                    :disabled="(editMode && !product.category) || (!editMode && !product.category_id) ||
                                                   (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                                   (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage)"
                                    @add-color="addColor"
                                    @added-color="addNewColor"
                                    @remove-color="removeColor"
                                    @clear-value="product.new_colors = []"
                                    :key="renders"
                                />
                                <!--PATTERNS-->
                                <minitopolis-autocomplete
                                    :id="'pattern-' + product.id"
                                    v-model="product.patterns"
                                    class="w-full"
                                    label="Mønster"
                                    :items="attributes.patterns"
                                    mode="tags"
                                    model="patterns"
                                    :edit-mode="editMode"
                                    :admin-page="adminPage"
                                    send-type="name"
                                    :placeholder="(editMode && !product.category) || (!editMode && !product.category_id) ||
                                                (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                                (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Søg efter mønster ...'"
                                    :disabled="(editMode && !product.category) || (!editMode && !product.category_id) ||
                                                (parentCategoriesEquipmentInteriorToysBooks() && parentCategoriesClothingShoesAccessoriesBooks()) ||
                                                (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage)"
                                    :optional="true"
                                    @add-pattern="addPattern"
                                    @added-pattern="addNewPattern"
                                    @remove-pattern="removePattern"
                                    @clear-value="product.patterns = []"
                                    :key="renders"
                                />

                                <div class="flex flex-col xs:flex-row gap-4 w-full">
                                    <div class="flex flex-col w-full xs:w-1/2">
                                        <label class="form-label" :class="{ 'opacity-25' : (editMode && !product.category) ||
                                                    (!editMode && !product.category_id) ||
                                                    parentCategoriesNotIncludingBooks() ||
                                                    (editMode && product.category_id === 132) ||
                                                    (editMode && product.category_id === 378) ||
                                                    (currentParentCategory && currentParentCategory === 132) ||
                                                    (currentParentCategory && currentParentCategory === 378) ||
                                                    (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage) }"
                                               :for="'title-' + product.id">Titel
                                            <span
                                                v-if="currentParentCategory &&  !parentCategoriesNotIncludingBooks()"
                                                class="-mt-1 text-red-500 font-bold">*</span>
                                        </label>
                                        <div class="flex h-[40px]">
                                            <input
                                                :id="'title-' + product.id"
                                                v-model="product.title"
                                                class="form-input"
                                                type="text"
                                                :placeholder="(editMode && !product.category) ||
                                                    (!editMode && !product.category_id) ||
                                                    parentCategoriesNotIncludingBooks() ||
                                                    (editMode && product.category_id === 132) ||
                                                    (editMode && product.category_id === 378) ||
                                                    (currentParentCategory && currentParentCategory === 132) ||
                                                    (currentParentCategory && currentParentCategory === 378) ||
                                                    (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Sæt titel ...'"
                                                autocomplete="off"
                                                :disabled="
                                                    (editMode && !product.category) ||
                                                    (!editMode && !product.category_id) ||
                                                    parentCategoriesNotIncludingBooks() ||
                                                    (editMode && product.category_id === 132) ||
                                                    (editMode && product.category_id === 378) ||
                                                    (currentParentCategory && currentParentCategory === 132) ||
                                                    (currentParentCategory && currentParentCategory === 378) ||
                                                    (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage)
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div class="flex flex-col w-full xs:w-1/2">
                                        <label class="form-label"
                                               :class="{ 'opacity-25' : (editMode && !product.category) || (!editMode && !product.category_id) || (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage) }"
                                               :for="'description-' + product.id"
                                        >Kort beskrivelse
                                            <span
                                                v-if="parentCategoriesClothingAccessoriesShoes()"
                                                class="text-xs font-normal text-gray-500">(valgfrit)
                                            </span>
                                            <span
                                                v-if="currentParentCategory && !parentCategoriesClothingAccessoriesShoes()"
                                                class="-mt-1 text-red-500 font-bold">*</span>
                                        </label>
                                        <div class="flex h-[40px]">
                                            <input
                                                :id="'description-' + product.id"
                                                v-model="product.description"
                                                class="form-input"
                                                :class="{ 'border !border-red-300' : currentParentCategory && !parentCategoriesClothingAccessoriesShoes() && !product.description }"
                                                type="text"
                                                :placeholder="(editMode && !product.category) || (!editMode && !product.category_id) || (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage) ? 'Deaktiveret' : 'Sæt beskrivelse ...'"
                                                autocomplete="off"
                                                :disabled="(editMode && !product.category) || (!editMode && !product.category_id) || (editMode && (isEasyBookingsDisabled && booking.concept !== 'business') && !adminPage)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col xs:flex-row gap-4 w-full">
                                <div class="flex flex-col w-full xs:w-1/2">
                                    <div class="flex items-center">
                                        <label class="form-label" :class="{ 'opacity-25' : !product.category }"
                                               :for="'price-' + product.id">
                                            Pris
                                            <span v-if="currentParentCategory"
                                                  class="-mt-1 text-red-500 font-bold">*</span>
                                        </label>
                                    </div>

                                    <div class="flex h-[40px]">
                                        <input :id="'price-' + product.id" v-model="product.price"
                                               class="form-input-defined" type="number" placeholder="0"
                                               autocomplete="off" :disabled="!product.category"
                                               :class="{ 'border !border-red-300' : currentParentCategory && !product.price }" />
                                        <span class="form-input-defined-box"
                                              :class="{ 'isDisabled' : !product.category }">kr.</span>
                                    </div>
                                </div>
                                <div class="flex flex-col w-full xs:w-1/2">
                                    <div class="form-label"
                                         :class="{ 'opacity-25' : disablePriceHelperButton(product) || !parentCategoriesNotIncludingBooks() }">
                                        Prishjælper
                                    </div>
                                    <div class="flex h-full">
                                        <button class="price-helper-button price-helper-button-left max-h-[40px]"
                                                :disabled="disablePriceHelperButton(product) || !parentCategoriesNotIncludingBooks()"
                                                @click="product.price = averagePrice.value / 100">
                                            <span class="flex flex-col justify-center leading-none">
                                                <span class="text-sm">{{ averagePrice.pretty }}</span>
                                                <span class="text-xxs font-medium">Gns.</span>
                                            </span>
                                        </button>
                                        <button class="price-helper-button price-helper-button-right max-h-[40px]"
                                                :disabled="disablePriceHelperButton(product) || !parentCategoriesNotIncludingBooks()"
                                                @click="product.price = maxPrice.value / 100">
                                            <span class="flex flex-col justify-center leading-none">
                                                <span class="text-sm">{{ maxPrice.pretty }}</span>
                                                <span class="text-xxs font-medium">Højeste</span>
                                            </span>
                                        </button>
                                    </div>
                                    <div v-if="total" class="flex">
                                        <span class="ml-1 mt-1 text-xs font-bold">({{ total
                                            }} solgte produkter)</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="product.price >= 200" class="flex flex-col w-full mt-4">
                                <div class="text-xs my-2">
                                    <span v-if="adminPage"
                                    >Ved produkter over 200 kr., så muliggøres en minimumspris. Hvis en kunde er
                                        interesseret, men ikke til prisen. Dette er ikke et krav, så den kan ignoreres
                                        hvis ikke det ikke findes relevant.
                                    </span>
                                    <span v-if="!adminPage">Ved produkter til eller over 200 kr., så muliggøres en
                                        minimumspris. Dette er intet krav. <span
                                            v-if="editMode">Lad 0 tallet stå hvis ikke minimumspris er noget for
                                            dig.</span></span>
                                </div>

                                <label class="form-label" :for="'min-price-' + product.id">Minimumspris</label>
                                <div class="flex">
                                    <input :id="'min_price-' + product.id" v-model="product.min_price"
                                           class="form-input-defined" type="number" placeholder="..." />
                                    <span class="form-input-defined-box">kr.</span>
                                </div>
                            </div>
                            <div v-if="!adminPage" class="flex flex-col w-full mt-4">
                                <div class="w-full">
                                    <label class="form-label" :for="'product_note-' + product.id">Produkt
                                        note</label>
                                    <div>
                                        <textarea
                                            :id="'product_note-' + product.id"
                                            v-model="product.product_note"
                                            rows="3"
                                            class="form-input"
                                            placeholder="Hvis du ikke kan finde det relevante i kategori/mærke/størrelse/farve, så vælg Andet og angiv beskrivelse her ..."
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-between flex-wrap gap-2 xs:gap-4">
                            <button
                                :class="{ 'admin-gray-button h-fit': adminPage, 'button button-gray smallest-button w-full xxs:w-auto': !adminPage }"
                                @click="goBack">
                                <span
                                    class="hidden sm:inline-block lg:hidden 2xl:inline-block">Afslut
                                    oprettelsesmode</span>
                                <span class="inline-block sm:hidden lg:inline-block 2xl:hidden">Afslut</span>
                            </button>
                            <div class="flex items-center flex-wrap justify-end gap-2 w-full xxs:w-auto">
                                <button v-if="!editMode"
                                        :class="{ 'admin-blue-button h-fit': adminPage, 'button button-blue smallest-button w-full xxs:w-auto': !adminPage }"
                                        :disabled="!isValid(product)" @click="saveProduct(product)">
                                    <span>Opret produkt</span>
                                </button>
                                <div v-if="editMode"
                                     class="flex items-center flex-wrap gap-2 xs:gap-4 w-full xxs:w-auto">
                                    <button
                                        :class="{ 'admin-blue-button h-fit': adminPage, 'button button-blue smallest-button w-full xxs:w-auto': !adminPage }"
                                        :disabled="!isValid(product)"
                                        @click="updateProduct(product, goToNewProduct)">
                                        <span>Opdatér & afslut</span>
                                    </button>
                                    <div class="relative flex flex-col w-full xxs:w-auto"
                                         :class="{ 'mt-2 xs:mt-0': !adminPage && !notReachedProductLimit() }">
                                        <button
                                            :class="{ 'admin-border-button h-fit': adminPage, 'button border-button smallest-button w-full xxs:w-auto': !adminPage }"
                                            :disabled="!isValid(product) || !notReachedProductLimit() || ((booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)"
                                            @click="updateAndContinue(product)"
                                        >
                                            <span>Opdatér & fortsæt</span>
                                        </button>
                                        <span v-if="!adminPage && !notReachedProductLimit()"
                                              class="absolute -top-4 left-0 right-0 mx-auto w-fit text-xxs font-bold text-topolis-purple-600">Du
                                            kan ikke oprette flere</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button v-if="adminPage" @click="toggleAdvancedContent"
                                class="mt-4 flex items-center gap-2 rounded-xl bg-gray-100 hover:bg-gray-200 px-3 py-1">
                            <span class="text-black text-sm font-medium">Mere info</span>
                            <svg :class="{ 'rotate-180': showAdvancedContent, 'rotate-0': !showAdvancedContent }"
                                 class="w-4 h-4 transition-transform" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M19 9l-7 7-7-7" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <transition name="slide-fade">
                <div v-if="showAdvancedContent && adminPage"
                     class="lg:hidden bg-[#FCFCFC] mb-4 py-8 px-4 space-y-8 border-y border-[#E6E6E6]">
                    <div class="space-y-4">
                        <div class="flex flex-col xs:flex-row gap-4 w-full">
                            <div class="flex flex-col w-full xs:w-1/2">
                                <label class="form-label" :for="'pretty_name-' + product.id">Navn
                                    (Webshop)</label>
                                <div class="flex">
                                    <input :id="'pretty_name-' + product.id" v-model="product.pretty_name"
                                           class="form-input" type="text" placeholder="..." />
                                </div>
                            </div>
                            <!--Condition-->
                            <div class="flex flex-col w-full xs:w-1/2" ref="conditionDropdown">
                                <label class="form-label" :for="'condition-' + product.id">Stand</label>
                                <div class="relative w-full">
                                    <div
                                        class="flex rounded-lg divide-x divide-office-black w-full cursor-pointer"
                                        @click="showConditionDropdown = !showConditionDropdown">
                                        <div class="relative z-0 flex rounded-lg w-full">
                                            <div
                                                class="relative flex items-center py-2 px-3 border border-gray-300 border-r-none rounded-l-lg w-full">
                                                <svg v-if="product.condition"
                                                     class="h-5 w-5 text-office-sky-900"
                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                     fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clip-rule="evenodd" />
                                                </svg>
                                                <span
                                                    class="inline-flex ml-1 xs:ml-2.5 text-xs xs:text-sm font-medium text-gray-500 truncate select-none"
                                                    :class="{ 'text-black': product.condition }">{{ condition
                                                    }}</span>
                                            </div>
                                            <button
                                                type="button"
                                                class="relative inline-flex items-center p-2 border-r border-y border-gray-300 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-gray-100 focus:outline-none"
                                            >
                                                <svg
                                                    :class="{'rotate-180': showConditionDropdown, 'rotate-0': !showConditionDropdown}"
                                                    class="h-5 w-5 text-gray-500 transition transform duration-300"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                    fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd"
                                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                          clip-rule="evenodd" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <ul v-if="showConditionDropdown"
                                        class="origin-top-right absolute z-[9999] right-0 mt-2 w-full rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 focus:outline-none"
                                        tabindex="-1">
                                        <li
                                            class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                            role="option"
                                            :class="{ 'bg-office-sky-50 hover:bg-office-sky-100': product.condition === 'new' }"
                                            @click="selectCondition('new')"
                                        >
                                            <div class="flex-col">
                                                <div class="flex items-center justify-between">
                                                    <span class="font-medium">Nyt</span>
                                                    <span class="text-office-sky-900">
                                                        <svg v-if="product.condition === 'new'" class="h-5 w-5"
                                                             xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 20 20" fill="currentColor"
                                                             aria-hidden="true">
                                                            <path fill-rule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clip-rule="evenodd" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <p class="text-gray-500 mt-0.5">Produktet er helt nyt og
                                                    aldrig
                                                    blevet brugt.</p>
                                            </div>
                                        </li>
                                        <li
                                            class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                            :class="{ 'bg-office-sky-50 hover:bg-office-sky-100': product.condition === 'almost-new' }"
                                            role="option"
                                            @click="selectCondition('almost-new')"
                                        >
                                            <div class="flex flex-col">
                                                <div class="flex items-center justify-between">
                                                    <span class="font-medium">Næsten nyt</span>
                                                    <span class="text-office-sky-900">
                                                        <svg v-if="product.condition === 'almost-new'"
                                                             class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 20 20" fill="currentColor"
                                                             aria-hidden="true">
                                                            <path fill-rule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clip-rule="evenodd" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <p class="text-gray-500 mt-0.5">Produktet er brugt, men er
                                                    næsten
                                                    som ny.</p>
                                            </div>
                                        </li>
                                        <li
                                            class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                            :class="{ 'bg-office-sky-50 hover:bg-office-sky-100': product.condition === 'good-but-used' }"
                                            role="option"
                                            @click="selectCondition('good-but-used')"
                                        >
                                            <div class="flex flex-col">
                                                <div class="flex items-center justify-between">
                                                    <span class="font-medium">Godt, men brugt</span>
                                                    <span class="text-office-sky-900">
                                                        <svg v-if="product.condition === 'good-but-used'"
                                                             class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 20 20" fill="currentColor"
                                                             aria-hidden="true">
                                                            <path fill-rule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clip-rule="evenodd" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <p class="text-gray-500 mt-0.5">Produktet er brugt og det kan
                                                    ses,
                                                    men det kan stadig bruges.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!--                                    <div class="flex flex-col w-1/2">-->
                            <!--                                        <label class="form-label" :for="'min-price-' + product.id">Minimumspris</label>-->
                            <!--                                        <div class="flex">-->
                            <!--                                            <input :id="'min_price-' + product.id" v-model="product.min_price" class="form-input-defined" type="text" placeholder="0" />-->
                            <!--                                            <span class="form-input-defined-box">kr.</span>-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->
                        </div>
                        <div class="flex flex-col xs:flex-row gap-4 w-full">
                            <div class="relative flex flex-col w-full xs:w-1/2">
                                <span class="form-label">Mulighed for forsendelse</span>
                                <div class="flex justify-between w-full gap-2">
                                    <label class="placement-radio-card"
                                           :class="{ active: product.is_shippable === false || product.is_shippable === 0 }">
                                        <input id="radio-card-1" v-model="product.is_shippable" type="radio"
                                               name="radio-card" :value="false" class="hidden" />
                                        <span id="radio-card-1-label"
                                              class="text-center">Kun klik og hent</span>
                                    </label>

                                    <label class="placement-radio-card"
                                           :class="{ active: product.is_shippable === true || product.is_shippable === 1 }">
                                        <input id="radio-card-2" v-model="product.is_shippable" type="radio"
                                               name="radio-card" :value="true" class="hidden" />
                                        <span id="radio-card-2-label"
                                              class="text-center">Kan afsendes</span>
                                    </label>
                                </div>
                            </div>
                            <div class="flex flex-col w-full xs:w-1/2">
                                <label class="form-label" :for="'retail-price-' + product.id">Oprindelig
                                    pris
                                    (ny pris)</label>
                                <div class="flex">
                                    <input :id="'retail_price-' + product.id" v-model="product.retail_price"
                                           class="form-input-defined" type="text" placeholder="0" />
                                    <span class="form-input-defined-box">kr.</span>
                                </div>
                            </div>
                        </div>
                        <!--Tags-->
                        <minitopolis-autocomplete
                            :id="'tags-' + product.id"
                            v-model="product.tags"
                            class="w-full"
                            label="Tags"
                            :items="attributes.tags"
                            mode="tags"
                            model="tags"
                            :edit-mode="editMode"
                            :admin-page="adminPage"
                            send-type="name"
                            :placeholder="(editMode && !product.category) || (!editMode && !product.category_id) && !adminPage ? 'Deaktiveret' : 'Søg efter tag ...'"
                            :disabled="(editMode && !product.category) || (!editMode && !product.category_id) && !adminPage"
                            :optional="true"
                            @add-tag="addTag"
                            @added-tag="addNewTag"
                            @remove-tag="removeTag"
                            @clear-value="product.tags = []"
                            :key="renders"
                        />
                        <div class="flex flex-col space-y-8 w-full mb-4">
                            <div class="w-full">
                                <label class="form-label"
                                       :for="'pretty_description-' + product.id">Beskrivelse (Webshop)</label>
                                <div>
                                            <textarea :id="'pretty_description-' + product.id"
                                                      v-model="product.pretty_description" rows="3" class="form-input"
                                                      placeholder="..."></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex sm:hidden w-full items-center justify-between flex-wrap gap-2 xs:gap-4">
                        <button
                            :class="{ 'admin-gray-button h-fit': adminPage, 'button button-gray smallest-button w-full xxs:w-auto': !adminPage }"
                            @click="goBack">
                            <span
                                class="hidden sm:inline-block lg:hidden 2xl:inline-block">Afslut
                                oprettelsesmode</span>
                            <span class="inline-block sm:hidden lg:inline-block 2xl:hidden">Afslut</span>
                        </button>
                        <div class="flex items-center flex-wrap justify-end gap-2 w-full xxs:w-auto">
                            <button v-if="!editMode"
                                    :class="{ 'admin-blue-button h-fit': adminPage, 'button button-blue smallest-button w-full xxs:w-auto': !adminPage }"
                                    :disabled="!isValid(product)" @click="saveProduct(product)">
                                <span>Opret produkt</span>
                            </button>
                            <div v-if="editMode"
                                 class="flex items-center flex-wrap gap-2 xs:gap-4 w-full xxs:w-auto">
                                <button
                                    :class="{ 'admin-blue-button h-fit': adminPage, 'button button-blue smallest-button w-full xxs:w-auto': !adminPage }"
                                    :disabled="!isValid(product)"
                                    @click="updateProduct(product, goToNewProduct)">
                                    <span>Opdatér & afslut</span>
                                </button>
                                <div class="relative flex flex-col w-full xxs:w-auto"
                                     :class="{ 'mt-2 xs:mt-0': !adminPage && !notReachedProductLimit() }">
                                    <button
                                        :class="{ 'admin-border-button h-fit': adminPage, 'button border-button smallest-button w-full xxs:w-auto': !adminPage }"
                                        :disabled="!isValid(product) || !notReachedProductLimit() || ((booking.concept !== 'easy' && booking.concept !== 'business') && !adminPage)"
                                        @click="updateAndContinue(product)"
                                    >
                                        <span>Opdatér & fortsæt</span>
                                    </button>
                                    <span v-if="!adminPage && !notReachedProductLimit()"
                                          class="absolute -top-4 left-0 right-0 mx-auto w-fit text-xxs font-bold text-topolis-purple-600">Du
                                        kan ikke oprette flere</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <product-preview :condition="condition" :current-parent-category="currentParentCategory"
                             :is-valid="isValid(product)" :edit-mode="editMode" :advanced-mode="showAdvancedContent"
                             :is-webshop-valid="isWebshopValid(product)" :product="product" :admin-page="adminPage" />
        </div>
        <transition name="slide-fade">
            <div v-if="showAdvancedContent && adminPage"
                 class="hidden lg:block mb-8 py-8 px-4 space-y-8 border-x border-b border-t-0 border-[#E6E6E6] rounded-b-2xl">
                <div class="space-y-4">
                    <div class="flex flex-col xs:flex-row gap-4 w-full">
                        <div class="flex flex-col w-full xs:w-1/2">
                            <label class="form-label" :for="'pretty_name-' + product.id">Navn
                                (Webshop)</label>
                            <div class="flex">
                                <input :id="'pretty_name-' + product.id" v-model="product.pretty_name"
                                       class="form-input" type="text" placeholder="..." />
                            </div>
                        </div>
                        <!--Condition-->
                        <div class="flex flex-col w-full xs:w-1/2" ref="conditionDropdown">
                            <label class="form-label" :for="'condition-' + product.id">Stand</label>
                            <div class="relative w-full">
                                <div
                                    class="flex rounded-lg divide-x divide-office-black w-full cursor-pointer"
                                    @click="showConditionDropdown = !showConditionDropdown">
                                    <div class="relative z-0 flex rounded-lg w-full">
                                        <div
                                            class="relative flex items-center py-2 px-3 border border-gray-300 border-r-none rounded-l-lg w-full">
                                            <svg v-if="product.condition"
                                                 class="h-5 w-5 text-office-sky-900"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                 fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd"
                                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                      clip-rule="evenodd" />
                                            </svg>
                                            <span
                                                class="inline-flex ml-1 xs:ml-2.5 text-xs xs:text-sm font-medium text-gray-500 truncate select-none"
                                                :class="{ 'text-black': product.condition }">{{ condition
                                                }}</span>
                                        </div>
                                        <button
                                            type="button"
                                            class="relative inline-flex items-center p-2 border-r border-y border-gray-300 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-gray-100 focus:outline-none"
                                        >
                                            <svg
                                                :class="{'rotate-180': showConditionDropdown, 'rotate-0': !showConditionDropdown}"
                                                class="h-5 w-5 text-gray-500 transition transform duration-300"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd"
                                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                      clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <ul v-if="showConditionDropdown"
                                    class="origin-top-right absolute z-[9999] right-0 mt-2 w-full rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 focus:outline-none"
                                    tabindex="-1">
                                    <li
                                        class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                        role="option"
                                        :class="{ 'bg-office-sky-50 hover:bg-office-sky-100': product.condition === 'new' }"
                                        @click="selectCondition('new')"
                                    >
                                        <div class="flex-col">
                                            <div class="flex items-center justify-between">
                                                <span class="font-medium">Nyt</span>
                                                <span class="text-office-sky-900">
                                                    <svg v-if="product.condition === 'new'" class="h-5 w-5"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 20 20" fill="currentColor"
                                                         aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                              clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <p class="text-gray-500 mt-0.5">Produktet er helt nyt og
                                                aldrig
                                                blevet brugt.</p>
                                        </div>
                                    </li>
                                    <li
                                        class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                        :class="{ 'bg-office-sky-50 hover:bg-office-sky-100': product.condition === 'almost-new' }"
                                        role="option"
                                        @click="selectCondition('almost-new')"
                                    >
                                        <div class="flex flex-col">
                                            <div class="flex items-center justify-between">
                                                <span class="font-medium">Næsten nyt</span>
                                                <span class="text-office-sky-900">
                                                    <svg v-if="product.condition === 'almost-new'"
                                                         class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 20 20" fill="currentColor"
                                                         aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                              clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <p class="text-gray-500 mt-0.5">Produktet er brugt, men er
                                                næsten
                                                som ny.</p>
                                        </div>
                                    </li>
                                    <li
                                        class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100"
                                        :class="{ 'bg-office-sky-50 hover:bg-office-sky-100': product.condition === 'good-but-used' }"
                                        role="option"
                                        @click="selectCondition('good-but-used')"
                                    >
                                        <div class="flex flex-col">
                                            <div class="flex items-center justify-between">
                                                <span class="font-medium">Godt, men brugt</span>
                                                <span class="text-office-sky-900">
                                                    <svg v-if="product.condition === 'good-but-used'"
                                                         class="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 20 20" fill="currentColor"
                                                         aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                              clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <p class="text-gray-500 mt-0.5">Produktet er brugt og det kan
                                                ses,
                                                men det kan stadig bruges.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!--                                    <div class="flex flex-col w-1/2">-->
                        <!--                                        <label class="form-label" :for="'min-price-' + product.id">Minimumspris</label>-->
                        <!--                                        <div class="flex">-->
                        <!--                                            <input :id="'min_price-' + product.id" v-model="product.min_price" class="form-input-defined" type="text" placeholder="0" />-->
                        <!--                                            <span class="form-input-defined-box">kr.</span>-->
                        <!--                                        </div>-->
                        <!--                                    </div>-->
                    </div>
                    <div class="flex flex-col xs:flex-row gap-4 w-full">
                        <div class="relative flex flex-col w-full xs:w-1/2">
                            <span class="form-label">Mulighed for forsendelse</span>
                            <div class="flex justify-between w-full gap-2">
                                <label class="placement-radio-card"
                                       :class="{ active: product.is_shippable === false || product.is_shippable === 0 }">
                                    <input id="radio-card-1" v-model="product.is_shippable" type="radio"
                                           name="radio-card" :value="false" class="hidden" />
                                    <span id="radio-card-1-label"
                                          class="text-center">Kun klik og hent</span>
                                </label>

                                <label class="placement-radio-card"
                                       :class="{ active: product.is_shippable === true || product.is_shippable === 1 }">
                                    <input id="radio-card-2" v-model="product.is_shippable" type="radio"
                                           name="radio-card" :value="true" class="hidden" />
                                    <span id="radio-card-2-label"
                                          class="text-center">Kan afsendes</span>
                                </label>
                            </div>
                        </div>
                        <div class="flex flex-col w-full xs:w-1/2">
                            <label class="form-label" :for="'retail-price-' + product.id">Oprindelig
                                pris
                                (ny pris)</label>
                            <div class="flex">
                                <input :id="'retail_price-' + product.id" v-model="product.retail_price"
                                       class="form-input-defined" type="text" placeholder="0" />
                                <span class="form-input-defined-box">kr.</span>
                            </div>
                        </div>
                    </div>
                    <!--Tags-->
                    <minitopolis-autocomplete
                        :id="'tags-' + product.id"
                        v-model="product.tags"
                        class="w-full"
                        label="Tags"
                        :items="attributes.tags"
                        mode="tags"
                        model="tags"
                        :edit-mode="editMode"
                        :admin-page="adminPage"
                        send-type="name"
                        :placeholder="(editMode && !product.category) || (!editMode && !product.category_id) && !adminPage ? 'Deaktiveret' : 'Søg efter tag ...'"
                        :disabled="(editMode && !product.category) || (!editMode && !product.category_id) && !adminPage"
                        :optional="true"
                        @add-tag="addTag"
                        @added-tag="addNewTag"
                        @remove-tag="removeTag"
                        @clear-value="product.tags = []"
                        :key="renders"
                    />
                    <div class="flex flex-col space-y-8 w-full mb-4">
                        <div class="w-full">
                            <label class="form-label"
                                   :for="'pretty_description-' + product.id">Beskrivelse (Webshop)</label>
                            <div>
                                            <textarea :id="'pretty_description-' + product.id"
                                                      v-model="product.pretty_description" rows="3" class="form-input"
                                                      placeholder="..."></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <drawer name="create-guide" deactivate-outside-click drawer-header="Oprettelsesguide"
                :product="product" :current-parent-category="currentParentCategory"
                guide :admin-page="adminPage" :direction-left="!adminPage" :edit-mode="editMode"></drawer>
        <drawer name="size-guide" :edit-mode="editMode" deactivate-outside-click drawer-header="Størrelsesguide"
                :current-parent-category="currentParentCategory" guide :admin-page="adminPage"
                :direction-left="!adminPage"></drawer>
        <drawer name="color-guide" :items="attributes.colors" :colors="product.new_colors"
                deactivate-outside-click
                drawer-header="Farve guide"
                guide
                :admin-page="adminPage" :edit-mode="editMode" @add-color="addColor" @remove-color="removeColor"
                :direction-left="!adminPage"></drawer>
        <drawer name="pattern-guide" :items="attributes.patterns" :patterns="product.patterns"
                deactivate-outside-click
                drawer-header="Mønster guide"
                guide
                :admin-page="adminPage" :edit-mode="editMode" @add-pattern="addPattern" @remove-pattern="removePattern"
                :direction-left="!adminPage"></drawer>
        <drawer name="tag-guide" :items="attributes.tags" :tags="product.tags"
                deactivate-outside-click
                drawer-header="Tag guide"
                guide
                :admin-page="adminPage" :edit-mode="editMode" @add-tag="addTag" @remove-tag="removeTag"
                :direction-left="!adminPage"></drawer>
    </div>
</template>

<script>
    import axios from "axios";
    import ProductPreview from "./ProductPreview.vue";
    import Drawer from "./Drawer.vue";
    import LoadingSpinner from "./LoadingSpinner.vue";

    const CATEGORY_ANDET = 132;
    const CATEGORY_FODTOJ = 621;
    const CATEGORY_UDSTYR = 144;
    const CATEGORY_INTERIOR = 620;
    const CATEGORY_LEGETOJ = 213;
    const CATEGORY_TOJ = 351;
    const CATEGORY_OVERTOJ = 221;
    const CATEGORY_ACCESSORIES = 376;
    const CATEGORY_BOGER_SPIL = 377;
    const CATEGORY_UKENDT = 378;

    export default {
        components: { LoadingSpinner, Drawer, ProductPreview },
        props: {
            booking: {},
            endpoint: {},
            attributes: {},
            editProduct: {},
            editVariant: {},
            adminPage: {},
            notAllowedToCreateMore: {
                type: Boolean,
                default: true,
                required: false
            },
            editMode: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        data() {
            return {
                product: {
                    name: null,
                    category: null,
                    size: null,
                    new_colors: [],
                    patterns: [],
                    brand: null,
                    price: null,
                    marked_for_webshop: false,
                    title: null,
                    description: null,
                    pretty_name: null,
                    pretty_description: null,
                    condition: null,
                    retail_price: null,
                    min_price: null,
                    is_shippable: true,
                    product_note: null,
                    tags: [],
                    target_segment: localStorage.getItem("lastTargetSegment") || "children" // Set default target_segment to 'children' or value from localStorage if available
                },
                lastTargetSegment: localStorage.getItem("lastTargetSegment") || "children", // Track the last selected target segment
                averagePrice: {
                    value: null,
                    pretty: null
                },
                maxPrice: {
                    value: null,
                    pretty: null
                },
                currentParentCategory: null,
                new_parent_category: null,
                total: null,
                tenantName: null,
                parentCategoryIds: [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_LEGETOJ, CATEGORY_UDSTYR, CATEGORY_INTERIOR, CATEGORY_FODTOJ, CATEGORY_ACCESSORIES, CATEGORY_BOGER_SPIL],
                otherCategoryId: CATEGORY_ANDET,
                unknownCategoryId: CATEGORY_UKENDT,
                showConditionDropdown: false,
                showTargetSegmentDropdown: false,
                currentTab: 1,
                goToNewProduct: false,
                loading: false,
                newCategoryCreated: false,
                newBrandCreated: false,
                isBrandNew: false,
                newSizeCreated: false,
                newColorCreated: false,
                newPatternCreated: false,
                newTagCreated: false,
                renders: 0,
                showAdvancedContent: false
            };
        },
        watch: {
            newBrandCreated(newValue) {
                this.isBrandNew = newValue;
            },
            showTargetSegmentDropdown(newVal) {
                this.updateDropdownEventListeners(newVal, "target");
            },
            showConditionDropdown(newVal) {
                this.updateDropdownEventListeners(newVal, "condition");
            }
        },
        created() {
            // Check localStorage for the state of showAdvancedContent
            const savedState = localStorage.getItem("showAdvancedContent");
            if (savedState !== null) {
                this.showAdvancedContent = JSON.parse(savedState);
            }
        },
        computed: {
            condition() {
                if (this.product) {
                    if (this.product.condition === "new") {
                        return "Ny";
                    }
                    if (this.product.condition === "almost-new") {
                        return "Næsten ny";
                    }
                    if (this.product.condition === "good-but-used") {
                        return "God, men brugt";
                    }

                    return "-- Vælg stand --";
                }
            },
            targetSegment() {
                if (this.product) {
                    if (this.product.target_segment === "children") {
                        return "Børneprodukt";
                    }
                    if (this.product.target_segment === "mama") {
                        return "MAMA produkt";
                    }

                    return "-- Vælg Segment --";
                }
            },
            // for target segment dropdown, as in editMode, we do not want users to be able to select MAMA if parent category is TOYS or BOOKS/GAMES
            shouldDisableDropdown() {
                if (this.editMode) {
                    if (this.product && this.product.category) {
                        return this.currentParentCategory.value === CATEGORY_BOGER_SPIL || this.currentParentCategory.value === CATEGORY_LEGETOJ;
                    }
                }
                return false;
            },
            isAnyDropdownShown() {
                return this.showTargetSegmentDropdown || this.showConditionDropdown;
            },
            isEasyBookingsDisabled() {
                return this.disableEasyBookings();
            }
        },
        mounted() {
            this.loading = true;
            this.$nextTick(() => {
                this.tenantName = "egaa_"; // window.tenantName;
                this.loading = false;
            });
            // Tabs are used in the drawer component
            this.emitter.on("change-current-tab", (value) => {
                this.currentTab = value;
                window.localStorage.setItem("current-tab", value);
            });
            if (this.editMode) {
                this.product.id = this.editProduct.id;
                // this.product.category_id = this.editProduct.category_id;
                // this.product.category = this.editProduct.category;
                this.product.name = this.editProduct.name;
                this.product.title = this.editProduct.title;
                // this.product.brand = this.editProduct.brand;
                this.product.pretty_name = this.editProduct.pretty_name;
                this.product.target_segment = this.editProduct.target_segment;
                // this.product.size = this.editVariant.size;
                // this.product.color = this.editProduct.attributes.color;
                // this.product.new_colors = this.editVariant.colors;
                // this.product.patterns = this.editVariant.patterns;
                this.product.price = this.editVariant.price.value.amount / 100;
                this.product.marked_for_webshop = this.editVariant.marked_for_webshop;
                this.product.description = this.editVariant.description;
                this.product.pretty_description = this.editVariant.pretty_description;
                this.product.product_note = this.editVariant.product_note;
                this.product.condition = this.editVariant.condition;
                this.product.retail_price = this.editVariant.retail_price.value.amount / 100;
                this.product.min_price = this.editVariant.min_price.value.amount / 100;
                this.product.is_shippable = this.editVariant.is_shippable;

                this.currentParentCategory = this.findParentCategory(this.editProduct.category);

                this.setCategoryId({
                    product: this.editProduct
                });

                // if (!this.isBrandNew) {
                //     if (this.parentCategoriesNotIncludingBooks()) {
                //         this.setBrandId({
                //             product: this.editProduct
                //         });
                //     }
                // }

                if (!this.parentCategoriesEquipmentInteriorToysBooks() || !this.parentCategoriesClothingShoesAccessoriesBooks() || this.currentParentCategory.value !== CATEGORY_ANDET || this.currentParentCategory.value !== CATEGORY_UKENDT) {
                    this.setBrandId({
                        product: this.editProduct
                    });
                }

                if (this.parentCategoriesClothingAccessoriesShoes()) {
                    this.setSizeId({
                        product: this.editVariant
                    });
                }

                this.setColors({
                    product: this.editVariant
                });
                this.setPatterns({
                    product: this.editVariant
                });
                this.setTags({
                    product: this.editVariant
                });
                this.setParentCategoryId({ parent_id: this.editProduct.category.parent_id, product: this.editProduct });

                // Update lastTargetSegment with current value from the DB when in edit mode
                this.lastTargetSegment = this.editProduct.target_segment || "children";
                localStorage.setItem("lastTargetSegment", this.lastTargetSegment); // Persist the segment
            }
            this.notReachedProductLimit();
        },
        methods: {
            selectedProductValue(product, next) {
                setTimeout(() => {
                    if (typeof next !== "undefined" && next) {
                        document.querySelector("#" + next).focus();
                    }
                    if (product.category && product.brand) {
                        product.pretty_name = product.brand.label + " " + product.category.label;

                        axios
                            .post("/price-calculator", {
                                category: product.category_id,
                                brand: product.brand.label
                            })
                            .then((response) => {
                                this.total = response.data.total;
                                if (this.total < 2) {
                                    this.averagePrice = {
                                        value: null,
                                        pretty: null
                                    };
                                    this.maxPrice = {
                                        value: null,
                                        pretty: null
                                    };
                                } else {
                                    this.$nextTick(() => {
                                        this.averagePrice = response.data.averagePrice;
                                        this.maxPrice = response.data.maxPrice;
                                    });
                                }

                                if (!this.editProduct && this.total > 3) {
                                    // TODO: only set if price calculator is active
                                    product.price = response.data.averagePrice.value / 100;
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                }, 50);
            },
            setParentCategoryId(eventData) {
                if (eventData.parent_id) {
                    this.currentParentCategory = this.findParentCategory(eventData);
                } else {
                    if (this.editMode) {
                        if (eventData.product.category_id === CATEGORY_ANDET || eventData.product.category_id === CATEGORY_UKENDT) {
                            this.currentParentCategory = eventData.product.category_id;
                        }
                    } else {
                        if (eventData.value === CATEGORY_ANDET || eventData.value === CATEGORY_UKENDT) {
                            this.currentParentCategory = eventData.value;
                        }
                    }
                }
            },
            findParentCategory(category) {
                let parentId;

                if (category.parent_id) {
                    parentId = category.parent_id;
                } else {
                    if (category.id === 132 || category.id === 378) {
                        parentId = category.id;
                    }
                }
                return this.attributes.categories.find((cat) => {
                    return cat.value === parentId;
                });
            },
            setNewParentCategory(product) {
                axios
                    .post("/admin/produkter/kategorier/" + product.category_id + "/opdater-parent", {
                        parent_id: this.new_parent_category
                    })
                    .then((response) => {
                        this.setParentCategoryId(response.data.category);
                    });
            },
            addCategory(category) {
                this.attributes.categories.push(category);
            },
            addBrand(brand) {
                this.attributes.brands.push(brand);
            },
            addSize(size) {
                let newSize = {
                    value: size.id,
                    label: size.name
                };
                this.attributes.sizes.push(newSize);
                this.setSizeId(newSize);
            },
            addNewColor(color) {
                this.attributes.colors.push(color);
            },
            addNewPattern(pattern) {
                this.attributes.patterns.push(pattern);
            },
            addNewTag(tag) {
                this.attributes.tags.push(tag);
            },
            setBrandId(payload) {
                if (this.editMode) {
                    if (payload.label && this.product.brand !== payload.label) {
                        this.product.brand = {
                            label: payload.label,
                            value: payload.value
                        };
                    } else {
                        // Otherwise, use the brand from the product
                        this.product.brand = {
                            label: payload.product.brand.name,
                            value: payload.product.brand.id
                        };
                    }
                } else {
                    this.product.brand = {
                        label: payload.label,
                        value: payload.value
                    };
                }
            },
            setSizeId(payload) {
                if (this.editMode) {
                    if (payload.label && this.product.size !== payload.label) {
                        this.product.size = {
                            label: payload.label,
                            value: payload.value
                        };
                    } else {
                        // Otherwise, use the size from the product's variant
                        this.product.size = {
                            label: payload.product.size.name,
                            value: payload.product.size.id
                        };
                    }
                } else {
                    this.product.size = {
                        label: payload.label,
                        value: payload.value
                    };
                }
            },
            addColor(payload) {
                // enforce reactivity as we add a color from two different components
                this.renders += 1;
                if (this.editMode) {
                    this.product.new_colors.push(payload);
                } else {
                    if (!this.product.new_colors) {
                        this.product.new_colors = [];
                    }
                    this.product.new_colors.push(payload);
                }
            },
            removeColor(payload) {
                //enforce reactivity as we remove a color from two different components
                this.renders += 1;
                let index = this.product.new_colors.findIndex(color => color.value === payload.value);

                if (index !== -1) {
                    this.product.new_colors.splice(index, 1);
                }
            },
            setColors(payload) {
                if (this.editMode) {
                    this.product.new_colors = payload.product.colors.map(color => {
                        return {
                            label: color.name,
                            value: color.id,
                            hex: color.hex_code
                        };
                    });
                } else {
                    this.product.new_colors = {
                        label: payload.label,
                        value: payload.value,
                        hex: payload.hex_code
                    };
                }
            },
            setPatterns(payload) {
                if (this.editMode) {
                    this.product.patterns = payload.product.patterns.map(pattern => {
                        return {
                            label: pattern.name,
                            value: pattern.id
                        };
                    });
                } else {
                    this.product.patterns = {
                        label: payload.label,
                        value: payload.value
                    };
                }
            },
            addPattern(payload) {
                // enforce reactivity as we add a pattern from two different components
                this.renders += 1;
                if (this.editMode) {
                    this.product.patterns.push(payload);
                } else {
                    if (!this.product.patterns) {
                        this.product.patterns = [];
                    }
                    this.product.patterns.push(payload);
                }
            },
            removePattern(payload) {
                // enforce reactivity as we remove a pattern from two different components
                this.renders += 1;
                let index = this.product.patterns.findIndex(pattern => pattern.value === payload.value);

                if (index !== -1) {
                    this.product.patterns.splice(index, 1);
                }
            },
            setTags(payload) {
                if (this.editMode) {
                    this.product.tags = payload.product.tags.map(tag => {
                        return {
                            label: tag.name,
                            value: tag.id
                        };
                    });
                } else {
                    this.product.tags = {
                        label: payload.label,
                        value: payload.value
                    };
                }
            },
            addTag(payload) {
                // enforce reactivity as we add a tag from two different components
                this.renders += 1;
                if (this.editMode) {
                    this.product.tags.push(payload);
                } else {
                    if (!this.product.tags) {
                        this.product.tags = [];
                    }
                    this.product.tags.push(payload);
                }
            },
            removeTag(payload) {
                // enforce reactivity as we remove a tag from two different components
                let index = this.product.tags.findIndex(tag => tag.value === payload.value);

                if (index !== -1) {
                    this.product.tags.splice(index, 1);
                }
            },
            setCategoryId(payload) {
                if (this.editMode) {
                    if (payload.label && this.product.category !== payload.label) {
                        this.product.category = {
                            label: payload.label,
                            value: payload.value
                        };
                        this.product.category_id = payload.value;
                    } else {
                        this.product.category = {
                            label: payload.product.category.name,
                            value: payload.product.category.id
                        };
                        this.product.category_id = payload.product.category.id;
                    }
                } else {
                    // If not in edit mode
                    this.product.category = {
                        label: payload.label,
                        value: payload.value
                    };
                    this.product.category_id = payload.value;
                }
            },
            notReachedProductLimit() {
                return this.notAllowedToCreateMore;
            },
            toggleAdvancedContent() {
                this.showAdvancedContent = !this.showAdvancedContent;
                // Save the state to localStorage
                localStorage.setItem("showAdvancedContent", JSON.stringify(this.showAdvancedContent));
            },
            parentCategoriesNotIncludingBooks() {
                if (!this.currentParentCategory) return false;

                // fields to deactivated if book category is selected. Therefore, all other categories than the book category is deactivated
                const deactivatedCategories = [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_LEGETOJ, CATEGORY_UDSTYR, CATEGORY_INTERIOR, CATEGORY_FODTOJ, CATEGORY_ACCESSORIES];
                return deactivatedCategories.includes(this.currentParentCategory.value);
            },
            parentCategoriesClothingShoesAccessoriesBooks() {
                if (!this.currentParentCategory) return false;

                // fields to deactivated if shoe, clothing or accessories categories are selected. Therefore, all other categories than the these three categories is deactivated
                const deactivatedCategories = [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_FODTOJ, CATEGORY_ACCESSORIES, CATEGORY_BOGER_SPIL];
                return deactivatedCategories.includes(this.currentParentCategory.value);
            },
            parentCategoriesEquipmentInteriorToysBooks() {
                if (!this.currentParentCategory) return false;

                // fields to deactivated if equipment, interior or toys categories are selected. Therefore, all other categories than the these three categories is deactivated
                const deactivatedCategories = [CATEGORY_LEGETOJ, CATEGORY_UDSTYR, CATEGORY_INTERIOR, CATEGORY_BOGER_SPIL];
                return deactivatedCategories.includes(this.currentParentCategory.value);
            },
            parentCategoriesClothingAccessoriesShoes() {
                if (!this.currentParentCategory) return false;

                // fields to deactivated if equipment, interior or toys categories are selected. Therefore, all other categories than the these three categories is deactivated
                const deactivatedCategories = [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_ACCESSORIES, CATEGORY_FODTOJ];
                return deactivatedCategories.includes(this.currentParentCategory.value);
            },
            disablePriceHelperButton(product) {
                return !product.category && !product.brand;
            },
            isValid(product) {
                const noSizeCategories = [CATEGORY_UDSTYR, CATEGORY_INTERIOR, CATEGORY_LEGETOJ];
                const noDescCategories = [CATEGORY_TOJ, CATEGORY_OVERTOJ, CATEGORY_ACCESSORIES, CATEGORY_FODTOJ];

                if (this.currentParentCategory) {
                    if (noDescCategories.includes(this.currentParentCategory.value)) {
                        return product.category && product.brand && product.new_colors && product.size && product.price;
                    }
                    if (noSizeCategories.includes(this.currentParentCategory.value)) {
                        return product.category && product.brand && product.new_colors && product.description && product.price;
                    }
                    if (CATEGORY_BOGER_SPIL === this.currentParentCategory.value) {
                        return product.category && product.title && product.description && product.price;
                    }
                    if (this.otherCategoryId === this.currentParentCategory) {
                        return product.category && product.brand && product.new_colors && product.description && product.price;
                    }
                    if (this.unknownCategoryId === this.currentParentCategory) {
                        return product.category && product.brand && product.new_colors && product.description && product.price;
                    }
                }

                // if (this.editMode && !this.currentParentCategory) {
                //     if (this.otherCategoryId === this.editProduct.category_id) {
                //         return product.category && product.brand && product.new_colors && product.description && product.price;
                //     }
                //     if (this.unknownCategoryId === this.editProduct.category_id) {
                //         return product.category && product.brand && product.new_colors && product.description && product.price;
                //     }
                // }
            },
            isWebshopValid(product) {
                return product.pretty_name && product.pretty_description;
            },
            updateAndContinue(product) {
                this.updateProduct(product, true);

                // Store the current target segment
                this.lastTargetSegment = product.target_segment;
                localStorage.setItem("lastTargetSegment", this.lastTargetSegment); // Persist the segment
            },
            updateProduct(product, goToNewProduct) {
                axios
                    .patch(this.endpoint, {
                        category_id: product.category.value,
                        product_id: this.product.uuid,
                        brand: product.brand,
                        price: product.price,
                        booking_id: this.booking.id,
                        parent_id: this.currentParentCategory.value,
                        size: product.size,
                        new_colors: product.new_colors,
                        patterns: product.patterns,
                        tags: product.tags,
                        title: product.title,
                        description: product.description,
                        name: product.name,
                        pretty_name: product.pretty_name,
                        pretty_description: product.pretty_description,
                        product_note: product.product_note,
                        condition: product.condition,
                        retail_price: product.retail_price,
                        min_price: product.min_price,
                        marked_for_webshop: product.marked_for_webshop,
                        is_shippable: product.is_shippable,
                        target_segment: product.target_segment
                    })
                    .then((response) => {
                        this.emitter.emit("show-toast", {
                            message: "Varen er blevet opdateret",
                            type: "success"
                        });
                        if (this.adminPage) {
                            if (goToNewProduct) {
                                setTimeout(() => {
                                    window.location.href = "/admin/produkter/" + this.booking.uuid + "/opret";
                                }, 500);
                            } else {
                                setTimeout(() => {
                                    window.location.href = "/admin/bookinger/" + this.booking.uuid + "/produkter";
                                }, 500);
                            }
                        } else {
                            if (goToNewProduct) {
                                setTimeout(() => {
                                    window.location.href = "/mit-minitopolis/" + this.booking.uuid + "/opret-produkt";
                                }, 500);
                            } else {
                                setTimeout(() => {
                                    window.location.href = "/mit-minitopolis/" + this.booking.uuid + "/dine-produkter";
                                }, 500);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.emitter.emit("show-toast", {
                            message: "Varen blev ikke opdateret",
                            type: "error"
                        });
                    });
            },
            saveProduct(product, fromDuplicate = false) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(this.endpoint, {
                            category_id: product.category.value,
                            brand: product.brand,
                            price: product.price,
                            booking_id: this.booking.id,
                            parent_id: this.currentParentCategory.value,
                            size: product.size,
                            new_colors: product.new_colors,
                            patterns: product.patterns,
                            tags: product.tags,
                            title: product.title,
                            description: product.description,
                            name: product.name,
                            pretty_name: product.pretty_name,
                            pretty_description: product.pretty_description,
                            product_note: product.product_note,
                            condition: product.condition,
                            retail_price: product.retail_price ? product.retail_price : 0,
                            min_price: product.min_price ? product.min_price : 0,
                            marked_for_webshop: product.marked_for_webshop,
                            is_shippable: product.is_shippable,
                            target_segment: product.target_segment
                        })
                        .then((response) => {
                            product.id = response.data.product.id;
                            product.uuid = response.data.product.uuid;
                            product.name = response.data.product.name;

                            // Clear the price buttons and total when saving a product
                            this.averagePrice = {
                                value: null,
                                pretty: null
                            };
                            this.maxPrice = {
                                value: null,
                                pretty: null
                            };
                            this.total = null;

                            if (!fromDuplicate) {
                                this.emitter.emit("show-toast", {
                                    message: "Varen er blevet oprettet",
                                    type: "success"
                                });
                                if (!this.adminPage && !this.notReachedProductLimit()) {
                                    setTimeout(() => {
                                        window.location.href = "/mit-minitopolis/" + this.booking.uuid + "/dine-produkter";
                                    }, 500);
                                } else {
                                    setTimeout(() => {
                                        // Store the current target segment
                                        this.lastTargetSegment = product.target_segment;
                                        localStorage.setItem("lastTargetSegment", this.lastTargetSegment); // Persist the segment

                                        window.location.reload();
                                    }, 500);
                                }
                            }
                            resolve();
                        })
                        .catch((error) => {
                            console.log(error);

                            this.emitter.emit("show-toast", {
                                message: "Varen blev ikke oprettet",
                                type: "error"
                            });

                            reject();
                        });
                });
            },
            allCategoriesExcludingParentCategories(categories) {
                const filterOutCategoriesWithNullParent = (categories) => {
                    console.log("new", categories);
                    return categories.filter(category => {
                        // Always show categories with id 132 or 378, depending on the mode
                        return category.parent !== null || category.value === 132 || category.value === 378;

                    });
                };

                if (this.editMode) {
                    // let currentCategoryId = this.product.category.value;
                    let currentCategoryId = this.editProduct.category.id;
                    let parentCategoryId = null;

                    for (let category of categories) {
                        if (category.value === currentCategoryId) {
                            if (currentCategoryId === 132 || currentCategoryId === 378) {
                                parentCategoryId = null;
                            } else {
                                parentCategoryId = category.parent.id; // Assuming the parent is an id, not an object.
                            }
                            break;
                        }
                    }

                    if (parentCategoryId) {
                        if (currentCategoryId !== 132 || currentCategoryId !== 378) {
                            // Filter categories which have the same 'parent.id'
                            return categories.filter(category => category.parent && category.parent.id === parentCategoryId);
                        } else {
                            return filterOutCategoriesWithNullParent(categories);
                        }
                    } else {
                        // We want to be able to change from "Andet" or "Ukendt" to anything else
                        return filterOutCategoriesWithNullParent(categories);
                        // Here we only show "Andet" or "Ukendt":
                        // return categories.filter(category => category.value === currentCategoryId);
                    }
                } else {
                    // Non-edit mode logic for mama segment
                    if (this.product.target_segment === "mama") {
                        // Filter categories excluding those with specific parent ids
                        let mamaCategories = categories.filter(category =>
                            category.parent?.id !== CATEGORY_BOGER_SPIL &&
                            category.parent?.id !== CATEGORY_LEGETOJ
                        );

                        return filterOutCategoriesWithNullParent(mamaCategories);
                    } else {
                        return filterOutCategoriesWithNullParent(categories);

                    }
                    // return categories.filter((item) => !this.parentCategoryIds.includes(item.value));
                }
            },
            allParentCategories(categories) {
                return categories.filter((item) => this.parentCategoryIds.includes(item.value));
            },
            selectCondition(condition) {
                this.product.condition = condition;
                this.showConditionDropdown = false;
            },
            updateDropdownEventListeners(show, type) {
                if (show) {
                    document.addEventListener("mousedown", this.handleClickOutside);
                    if (this.isAnyDropdownShown) {
                        document.addEventListener("keydown", this.handleEscapeKey);
                    }
                } else {
                    if (type === "target" && !this.showConditionDropdown) {
                        document.removeEventListener("mousedown", this.handleClickOutside);
                        document.removeEventListener("keydown", this.handleEscapeKey);
                    } else if (type === "condition" && !this.showTargetSegmentDropdown) {
                        document.removeEventListener("mousedown", this.handleClickOutside);
                        document.removeEventListener("keydown", this.handleEscapeKey);
                    }
                }
            },
            handleClickOutside(event) {
                if (this.$refs.targetDropdown && !this.$refs.targetDropdown.contains(event.target)) {
                    this.showTargetSegmentDropdown = false;
                }
                if (this.$refs.conditionDropdown && !this.$refs.conditionDropdown.contains(event.target)) {
                    this.showConditionDropdown = false;
                }
            },
            handleEscapeKey(event) {
                if (event.key === "Escape") {
                    this.showTargetSegmentDropdown = false;
                    this.showConditionDropdown = false;
                }
            },
            selectTargetSegment(segment) {
                this.product.target_segment = segment;
                this.showTargetSegmentDropdown = false;
            },
            clearCategoryField(payload) {
                this.product.category = null;
                this.product.category_id = null;
                if (!this.editMode) {
                    this.currentParentCategory = null;
                }
            },
            disableEasyBookings() {
                if (!this.adminPage) {
                    if (this.booking.concept === "easy") {
                        if (this.editProduct) {
                            if (this.editVariant.status === "store" || this.editVariant.status === "storage" || this.editVariant.status === "collected") {
                                return true;
                            }
                        }
                    }
                } else {
                    return false;
                }
            },
            goBack() {
                if (this.editMode) {
                    history.back();
                } else {
                    if (this.adminPage) {
                        setTimeout(() => {
                            window.location.href = "/admin/bookinger/" + this.booking.uuid + "/produkter";
                        }, 500);
                    } else {
                        setTimeout(() => {
                            window.location.href = "/mit-minitopolis/" + this.booking.uuid + "/dine-produkter";
                        }, 500);
                    }
                }
            }
        }
    };
</script>

<style>
    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(20px);
        opacity: 0;
    }
</style>